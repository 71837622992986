import React from "react";
import {
  Monitor,
  Phone,
  Apple,
  ShoppingCart,
  TrendingUp,
  Package,
  Truck,
  Timer,
} from "lucide-react";

const DownloadPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900 text-gray-100">
      {/* Hero Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1 className="md:text-5xl text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-cyan-300">
            WellMe Pipes
          </h1>
          <p className="md:text-xl text-sm text-blue-200">
            Revolutionary PVC Pipes E-commerce Platform
          </p>
        </div>

        {/* Download Cards Grid */}
        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {/* Android Download */}
          <div className="relative bg-gray-800/50 backdrop-blur rounded-xl p-6 shadow-lg hover:shadow-xl transition overflow-hidden border border-gray-700/50 hover:border-blue-500/50 group">
            <div className="absolute top-4 right-4 bg-gradient-to-r from-amber-500 to-yellow-500 text-white px-3 py-1 rounded-full text-sm font-semibold">
              Coming Soon
            </div>
            <div className="flex justify-center mb-4">
              <Phone className="w-16 h-16 text-blue-400 group-hover:text-blue-300 transition-colors" />
            </div>
            <h2 className="text-2xl font-bold mb-4 text-center text-blue-100">
              Android App
            </h2>
            <div className="space-y-4">
              <p className="text-blue-200 text-center">
                Coming soon to Play Store
              </p>
              <div className="flex justify-center opacity-50">
                <img
                  src="https://www.battlegroundsmobileindia.com/common/img/btn/btn_googleplay_250.png"
                  alt="Get it on Google Play"
                  className="h-16 object-contain"
                />
              </div>
              <button
                disabled
                className="w-full bg-gray-700 cursor-not-allowed text-gray-400 py-3 px-6 rounded-lg flex items-center justify-center gap-2 border border-gray-600"
              >
                <Timer className="w-5 h-5" />
                Launching Soon
              </button>
            </div>
          </div>

          {/* iOS Download */}
          <div className="relative bg-gray-800/50 backdrop-blur rounded-xl p-6 shadow-lg hover:shadow-xl transition overflow-hidden border border-gray-700/50 hover:border-blue-500/50 group">
            <div className="absolute top-4 right-4 bg-gradient-to-r from-amber-500 to-yellow-500 text-white px-3 py-1 rounded-full text-sm font-semibold">
              Coming Soon
            </div>
            <div className="flex justify-center mb-4">
              <Apple className="w-16 h-16 text-blue-400 group-hover:text-blue-300 transition-colors" />
            </div>
            <h2 className="text-2xl font-bold mb-4 text-center text-blue-100">
              iOS App
            </h2>
            <div className="space-y-4">
              <p className="text-blue-200 text-center">
                Coming soon to App Store
              </p>
              <div className="flex justify-center opacity-50">
                <img
                  src="https://www.battlegroundsmobileindia.com/common/img/btn/btn_appstore_250.png"
                  alt="Download on the App Store"
                  className="h-16 object-contain"
                />
              </div>
              <button
                disabled
                className="w-full bg-gray-700 cursor-not-allowed text-gray-400 py-3 px-6 rounded-lg flex items-center justify-center gap-2 border border-gray-600"
              >
                <Timer className="w-5 h-5" />
                Launching Soon
              </button>
            </div>
          </div>

          {/* PC/Web Version */}
          <div className="relative bg-gray-800/50 backdrop-blur rounded-xl p-6 shadow-lg hover:shadow-xl transition overflow-hidden border border-gray-700/50 hover:border-blue-500/50 group">
            <div className="absolute top-4 right-4 bg-gradient-to-r from-amber-500 to-yellow-500 text-white px-3 py-1 rounded-full text-sm font-semibold">
              Coming Soon
            </div>
            <div className="flex justify-center mb-4">
              <Monitor className="w-16 h-16 text-blue-400 group-hover:text-blue-300 transition-colors" />
            </div>
            <h2 className="text-2xl font-bold mb-4 text-center text-blue-100">
              Web Portal
            </h2>
            <div className="space-y-4">
              <p className="text-blue-200 text-center">
                Access your account through our web portal
              </p>
              <button
                disabled
                className="w-full bg-gray-700 cursor-not-allowed text-gray-400 py-3 px-6 rounded-lg flex items-center justify-center gap-2 border border-gray-600"
              >
                <Timer className="w-5 h-5" />
                Launching Soon
              </button>
            </div>
          </div>
        </div>

        {/* Coming Soon Features */}
        <div className="mt-16 text-center">
          <h2 className="text-3xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-cyan-300">
            Upcoming Features
          </h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-3xl mx-auto">
            <div className="bg-gray-800/50 backdrop-blur rounded-xl p-6 shadow-lg border border-gray-700/50 hover:border-blue-500/50 transition-colors">
              <h3 className="text-xl font-bold mb-2 text-blue-300">
                Smart Business Tools
              </h3>
              <p className="text-blue-100">
                Advanced account management, real-time inventory tracking, and
                detailed analytics
              </p>
            </div>
            <div className="bg-gray-800/50 backdrop-blur rounded-xl p-6 shadow-lg border border-gray-700/50 hover:border-blue-500/50 transition-colors">
              <h3 className="text-xl font-bold mb-2 text-blue-300">
                Seamless Experience
              </h3>
              <p className="text-blue-100">
                Cross-platform synchronization, instant price updates, and quick
                reordering
              </p>
            </div>
          </div>
        </div>

        {/* Newsletter Signup */}
        <div className="mt-16 text-center max-w-2xl mx-auto">
          <h3 className="text-2xl font-bold mb-4 text-blue-100">
            Stay Updated
          </h3>
          <p className="text-blue-200 mb-6">
            Be the first to know when our apps launch. Subscribe for updates!
          </p>
          <div className="flex gap-4 justify-center">
            <input
              type="email"
              placeholder="Enter your email"
              className="px-4 py-2 rounded-lg bg-gray-800 border border-gray-700 w-full max-w-sm text-white placeholder-gray-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none"
            />
            <button className="bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 text-white px-6 py-2 rounded-lg transition-all duration-200 shadow-lg shadow-blue-500/20">
              Notify Me
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
